import { useRouter } from "next/router";

import { Trans } from "@lingui/macro";
import { Button, Result } from "antd";
import styled from "styled-components";

import { TIMEOUT } from "utils/http-client-utils";

const Container = styled.div`
  height: 100vh;
  width: 100vw;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const ErrorPage = ({
  error,
}: {
  error: {
    config?: any;
    response?: any;
    message?: string;
    code: string;
  };
}) => {
  const router = useRouter();

  switch (error.code) {
    case "ECONNABORTED":
      return (
        <Container>
          <Result
            status="warning"
            title={<Trans>Connection timed out</Trans>}
            subTitle={
              <Trans>
                Failed to establish a connection after {TIMEOUT}, try reloading app to reconnect. Please contact
                administrator if issue persist.
              </Trans>
            }
            extra={
              <Button
                type="primary"
                key="reload"
                onClick={() => {
                  router.reload();
                }}
              >
                <Trans>Reload</Trans>
              </Button>
            }
          />
        </Container>
      );
    case "notfound":
      return (
        <Container>
          <Result
            status="error"
            title={<Trans>Page notfound</Trans>}
            subTitle={error.message || <Trans>Sorry, the page you visited does not exist.</Trans>}
            extra={
              <Button
                type="primary"
                key="reload"
                onClick={() => {
                  router.push("/");
                }}
              >
                <Trans>Go home</Trans>
              </Button>
            }
          />
        </Container>
      );
    case "unauthorized":
      return (
        <Container>
          <Result
            status="warning"
            title={<Trans>Unauthorized</Trans>}
            subTitle={error.message || <Trans>You do not have enough permission to view this page.</Trans>}
            extra={[
              <Button
                type="default"
                key="go-to-map"
                onClick={() => {
                  router.push("/");
                }}
              >
                <Trans>Go home</Trans>
              </Button>,
              <Button
                type="default"
                key="reload"
                onClick={() => {
                  router.back();
                }}
              >
                <Trans>Go back</Trans>
              </Button>,
            ]}
          />
        </Container>
      );
    case "ERR_BAD_REQUEST":
      return (
        <Container>
          <Result
            status="error"
            title={<Trans>Bad request</Trans>}
            subTitle={error.message}
            extra={[
              <Button
                type="default"
                key="reload"
                onClick={() => {
                  router.reload();
                }}
              >
                <Trans>Reload</Trans>
              </Button>,
              <Button
                type="primary"
                key="login"
                onClick={() => {
                  router.push("/login");
                }}
              >
                <Trans>Login</Trans>
              </Button>,
            ]}
          />
        </Container>
      );
    default:
      return (
        <Container>
          <Result
            status="error"
            title={<Trans>Could not start application</Trans>}
            subTitle={
              error?.message ? (
                <Trans>
                  {error?.message}, received status code {error?.response?.status} from {error?.config?.url}
                </Trans>
              ) : (
                <Trans>
                  An error occurred, try reloading app to restart. Please contact administrator if issue persist.
                </Trans>
              )
            }
            extra={
              <Button
                type="primary"
                key="reload"
                onClick={() => {
                  router.reload();
                }}
              >
                <Trans>Reload</Trans>
              </Button>
            }
          />
        </Container>
      );
  }
};

export default ErrorPage;
