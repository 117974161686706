import axios from "axios";
import dayjs from "dayjs";
import { atom } from "jotai";
import { atomEffect } from "jotai-effect";
import { atomWithStorage } from "jotai/utils";
import { isEmpty } from "lodash";
import { v4 } from "uuid";

import { appConfigAtom } from "atoms/configurations";
import { httpClientAtom } from "atoms/httpclient";
import { userSessionAtom } from "atoms/session";

import dataLayersQueries from "database/queries/data-layers";

import { DataLayer } from "types";

import { extractUuidFromURL } from "utils/uuid-utils";

/***
 * This Atom is responsible for fetching data layers from configuration and loading data into index DB
 * shouldFetchDataLayers: checks if the saved data layer content_updated_at is same as content_updated_at present in configuration,
 *  if content_updated_at does not match then we clear table and update.
 */

export const dataLayerKey = "navirec-map-data-layers";
export const dataLayerLastUpdatedAtKey = "navirec-map-data-layers-last-updated-at";

/** Saved option of which data layer to hide or show on map **/
type DataLayerSettings = { [key: string]: boolean };

const highlightedObjectIdAtom = atom<string | undefined>(undefined);
highlightedObjectIdAtom.debugLabel = "highlightedObjectId";

const dataLayersSettingsAtom = atomWithStorage<DataLayerSettings>(dataLayerKey, {}, undefined, {
  getOnInit: true,
});
dataLayersSettingsAtom.debugLabel = "dataLayersSettings";
const dataLayersPreviewAtom = atom<any>(undefined);
dataLayersPreviewAtom.debugLabel = "dataLayersPreview";

const dataLayerLastUpdatedAtAtom = atomWithStorage<{ [key: string]: string } | undefined>(
  dataLayerLastUpdatedAtKey,
  {},
  undefined,
  { getOnInit: true }
);
dataLayerLastUpdatedAtAtom.debugLabel = "dataLayerLastUpdatedAt";

interface DataLayers {
  allAreasDataLayerIds?: string[];
  allAreasDataLayer?: DataLayer[];
  queries: {
    getAllDataLayer: (account: string) => any[];
    getAllDataLayerFeatures: (account: string) => any[];
    getAllAreasDataLayerFeatures: (account: string, allAreasIds?: string[]) => any[];
    onGetLayerById: (layerId: string) => any;
    onGetLayerByAreaId: (account: string, areaId: string, allAreasIds?: string[]) => any;
    onDeleteDataLayer: (layerId: string) => void;
    onUpdateDataLayer: (layer: any, cb?: () => void) => void;
    onBulkUpdateDataLayers: (layers: any[], cb?: () => void) => void;
    onBulkAddDataLayer: (account: string, layers?: any[]) => void;
    onBulkAddDataLayerFeature: (account: string, layers?: any[]) => void;
    onClear: () => void;
  };
}

const dataLayersAtom = atom<DataLayers>({
  allAreasDataLayerIds: [],
  allAreasDataLayer: [],
  queries: dataLayersQueries,
});
dataLayersAtom.debugLabel = "dataLayers";

const loadingDataLayersAtom = atom<boolean>(false);
loadingDataLayersAtom.debugLabel = "loadingDataLayers";

const dataLayersEffectAtom = atomEffect((get, set) => {
  const session = get(userSessionAtom);
  const httpClient = get(httpClientAtom);
  const configuration = get(appConfigAtom);
  const loading = get(loadingDataLayersAtom);
  const dataLayersLastUpdatedAt = get(dataLayerLastUpdatedAtAtom);
  const accountId = session?.accountId as string;
  const dataLayerLastUpdatedAt = dataLayersLastUpdatedAt?.[accountId];

  if (loading) {
    return;
  }

  const account = String(session?.accountId);

  const dataLayers = configuration?.data_layers;

  const allAreasDataLayer = dataLayers?.filter(
    (layer) => layer.data_type === "area" && layer.area_group === null && layer.active
  );

  // persist data layers in index DB when function is mounted
  dataLayersQueries.onBulkAddDataLayer(
    account,
    dataLayers?.map((dataLayer) => ({ ...dataLayer, account: extractUuidFromURL(dataLayer.account) }))
  );

  const contentUpdatedAt = dataLayers?.reduce<string | undefined>((acc, layer) => {
    if (isEmpty(acc) || (acc && dayjs(layer.content_updated_at).isAfter(acc))) {
      return layer.content_updated_at;
    }
    return acc;
  }, undefined);
  const shouldFetchDataLayersFeatures = dayjs(contentUpdatedAt).isSame(dataLayerLastUpdatedAt);
  const dataLayerURLs = !shouldFetchDataLayersFeatures && dataLayers?.map((layer) => layer.content as string);

  if (dataLayerURLs && !isEmpty(dataLayerURLs)) {
    set(loadingDataLayersAtom, true);

    httpClient
      .multipleFetcher(dataLayerURLs)
      .then(
        axios.spread((...features) => {
          const dataLayersWithFeatures = dataLayers?.map((d: DataLayer, i) => {
            return { ...d, layer: features?.[i] };
          });
          const dataLayerFeatures: any[] = [];

          dataLayersWithFeatures?.forEach((dataLayer) => {
            if (!isEmpty(dataLayer.layer)) {
              const { layer, ...props } = dataLayer;
              const layersFeatures = layer?.features?.map((feature: any) => {
                return {
                  ...feature,
                  account: account,
                  id: v4(),
                  data_layer_id: props.id,
                  data_layer_type: props.data_type,
                };
              });

              dataLayerFeatures.push(...layersFeatures);
            }
          });
          dataLayersQueries.onBulkAddDataLayerFeature(account, dataLayerFeatures);

          set(dataLayerLastUpdatedAtAtom, (res: any) => ({ ...res, [accountId]: contentUpdatedAt }));
          set(loadingDataLayersAtom, false);
        })
      )
      .catch((e) => {
        set(loadingDataLayersAtom, false);
        console.error(e);
      });
  }

  set(dataLayersAtom, {
    allAreasDataLayerIds: allAreasDataLayer?.map((dataLayer) => dataLayer.id as string),
    allAreasDataLayer: allAreasDataLayer,
    queries: dataLayersQueries,
  });
});
dataLayersEffectAtom.debugLabel = "dataLayersEffectEffect";
dataLayersEffectAtom.debugPrivate = true;

export {
  dataLayersEffectAtom,
  dataLayersAtom,
  loadingDataLayersAtom,
  dataLayersSettingsAtom,
  dataLayersPreviewAtom,
  highlightedObjectIdAtom,
};
