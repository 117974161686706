import { message } from "antd";
import { isEmpty } from "lodash";
import get from "lodash/get";

import { db } from "database/db";

import account from "components/navigations/account";

const dataLayersQueries = {
  getAllDataLayer: (account: string) => {
    if (!db.isOpen()) {
      return [];
    }

    return db.dataLayer.where("account").equals(account).toArray();
  },
  getAllDataLayerFeatures: (account: string) => {
    if (!db.isOpen()) {
      return [];
    }

    return db.dataLayerFeature.where("account").equals(account).toArray();
  },
  getAllAreasDataLayerFeatures: (account: string, allAreasIds?: string[]) => {
    if (!db.isOpen()) {
      return [];
    }

    return db.dataLayerFeature
      .where("data_layer_type")
      .equals("area")
      .and(
        (area: { account: string; data_layer_id: string }) =>
          area.account === account && allAreasIds?.includes(area?.data_layer_id)
      )
      .toArray();
  },
  onDeleteDataLayer: (layerId: string) => {
    db.dataLayerFeature.delete(layerId);
  },
  onGetLayerById: (layerId: string) => {
    if (layerId) {
      return db.dataLayerFeature.where("id").equals(layerId).toArray();
    }
  },
  onGetLayerByAreaId: (account: string, areaId: string, allAreasIds?: string[]) =>
    db.dataLayerFeature
      .where("data_layer_type")
      .equals("area")
      .and(
        (area: { account: string; data_layer_id: string; properties: { area_id: string } }) =>
          area.account === account && area.properties.area_id === areaId && allAreasIds?.includes(area?.data_layer_id)
      )
      .first(),
  onBulkUpdateDataLayers: (layers: any[], cb?: () => void) => {
    db.dataLayerFeature.bulkPut(layers).then(() => {
      cb && cb();
    });
  },
  onUpdateDataLayer: (layer: any, cb?: () => void) => {
    db.dataLayerFeature.put(layer).then(() => {
      cb && cb();
    });
  },
  onBulkAddDataLayer: async (account: string, layers?: any[]) => {
    if (isEmpty(layers)) {
      return;
    }
    try {
      await db.dataLayer.where("account").equals(account).delete(); // clear existing records based on account
      await db.dataLayer.bulkPut(layers);
    } catch (error) {
      const errMsg = get(error, "message");
      message.error(errMsg);
    }
  },
  onBulkAddDataLayerFeature: async (account: string, layers?: any[]) => {
    try {
      await db.dataLayerFeature.where("account").equals(account).delete(); // clear existing records based on account
      await db.dataLayerFeature.bulkPut(layers);
    } catch (error) {
      const errMsg = get(error, "message");
      message.error(errMsg);
    }
  },
  onClear: async () => {
    if (db.isOpen()) {
      try {
        await db.dataLayerFeature.clear();
        await db.dataLayer.clear();
      } catch (error) {
        const errMsg = get(error, "message");
        console.error(errMsg);
      }
    }
  },
};

db;

export default dataLayersQueries;
