import { useEffect, useState } from "react";
import { datadogLogs } from "@datadog/browser-logs";
import { Configuration } from "types";
import { isEmpty } from "lodash";

const DATADOG_KEY = process.env.NEXT_PUBLIC_DATADOG_KEY;
const productionHost = ["beta.navirec.com", "app.navirec.com", "app.navirec.by"];

const useInitDataDogLogs = (configuration?: Configuration) => {
  const [isInitialized, setIsInitialized] = useState(false);

  useEffect(() => {
    if (isEmpty(DATADOG_KEY) || isEmpty(configuration?.user?.id) || isInitialized) {
      return;
    }

    const isProduction = productionHost.includes(window.location.host);

    datadogLogs.init({
      clientToken: DATADOG_KEY as string,
      site: "datadoghq.com",
      forwardErrorsToLogs: false,
      sessionSampleRate: 100,
      service: "navirec-web-app",
    });

    datadogLogs.setGlobalContext({
      env: isProduction ? "navirec-live" : "navirec-dev",
      "usr.id": configuration?.user?.id,
      "usr.email": configuration?.user?.email,
      "usr.name": configuration?.user?.username,
      "usr.name_display": configuration?.user?.name_display,
      "navirec.account_id": configuration?.account?.id,
      version: process.env.NEXT_PUBLIC_COMMIT_SHA,
    });
    setIsInitialized(true);
  }, [configuration]);
};

export { useInitDataDogLogs };
